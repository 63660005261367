<template>
  <div class="actions">
    <div
      v-if="reservation.ReportID"
      v-b-tooltip.hover
      class="text-primary link"
      :title="`${reservation.CustomFields.CUST_ReportFileName || 'Download'}`"
      @click="
        getReport({
          reportID: reservation.ReportID,
          reservationOrderID: reservation.ReservationOrderID,
          reportFileName: reservation.ReportFileName,
          attachmentToken: reservation.AttachmentToken
        })
      "
    >
      <font-awesome-icon :icon="['fas', 'download']" />
    </div>
    <a
      v-if="paymentStatus === 'Nog niet betaald'"
      v-b-tooltip.hover
      class="text-primary link"
      :href="`/betaling/${reservation.ReservationOrderID}`"
      title="Bestelling betalen"
    >
      <font-awesome-icon :icon="['fas', 'credit-card']" />
    </a>
    <div
      v-b-tooltip.hover.top="'Opnieuw bestellen'"
      class="text-primary link"
      @click="addReservationToCart"
    >
      <font-awesome-icon
        :class="{ 'fa-spin': addReservationToCartPending }"
        :icon="['fas', 'sync']"
      />
    </div>
    <div
      v-if="allowCancel"
      v-b-tooltip.hover
      class="text-primary link"
      title="Reservering aanpassen"
      @click="redoReservation"
    >
      <font-awesome-icon :icon="['fas', 'redo']" />
    </div>
    <div
      v-if="allowCancel"
      v-b-tooltip.hover
      class="text-primary link"
      title="Reservering annuleren"
      @click="show = true"
    >
      <font-awesome-icon :icon="['fas', 'trash']" />
    </div>
    <modal
      :modalid="`prompt${reservation.ReservationID}`"
      :message="promptMessage"
      :show="show"
      @hide="show = false"
      @confirm="cancelReservation()"
    />
  </div>
</template>

<script>
import Modal from '@/components/global/modal-prompt.vue'
import {
  addReservationToCart,
  adjustReservation,
  cancelReservation,
  getReservationReport
} from '@/services/ReservationService'

export default {
  components: {
    Modal
  },
  props: {
    reservation: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      addReservationToCartPending: false,
      show: false
    }
  },
  computed: {
    allowCancel() {
      const status = this.reservation.ReservationStatus
      if (this.reservation.OrderID) return false

      if (
        status === 'Cancelled' ||
        status === 'Expired' ||
        status === 'Order' ||
        status === 'Picked' ||
        status === 'Rejected'
      )
        return false
      if (this.$store.state.account.RoleName !== 'portal') return true

      return this.reservation.ContactID === this.$store.state.contactID
    },
    promptMessage: function() {
      return `Weet je zeker dat je bestelling #${this.reservation.ReservationID} wilt annuleren?`
    },
    paymentStatus: function() {
      if (this.reservation.CustomFields.CUST_IsPaid) return 'Betaald'
      if (this.reservation.CustomFields.CUST_OnInvoice) return 'Op factuur'
      return 'Nog niet betaald'
    }
  },
  methods: {
    cancelReservation: async function() {
      this.show = false
      //this.$bvModal.hide(`prompt${this.reservation.ReservationID}`)
      await cancelReservation({ reservationID: this.reservation.ReservationID })

      this.$emit('reservationCancelled')
    },
    addReservationToCart: async function() {
      this.addReservationToCartPending = true
      const result = await addReservationToCart({
        reservationID: this.reservation.ReservationID
      })

      if (result.data === 'OK') {
        this.$router.push({
          name: 'cart',
          query: { addedReservationItems: true }
        })
      }
      this.addReservationToCartPending = false
    },

    getReport: async function({
      reportID,
      reservationOrderID,
      reportFileName,
      attachmentToken
    }) {
      await getReservationReport({
        reservationOrderID: reservationOrderID,
        reportID: reportID,
        attachmentToken,
        fileName: `${reservationOrderID}-${reportFileName}`
      })
    },
    async redoReservation() {
      this.$toasted.show(
        'De reservering wordt opnieuw in je winkelwagen geplaatst, een moment geduld alstublieft.'
      )
      const result = await adjustReservation({
        reservationOrderID: this.reservation.ReservationID
      })

      if (result !== 'ERROR') {
        this.$toasted.show(
          'De reservering is geannuleerd en de artikelen zijn in uw winkelwagen geplaatst'
        )
        this.$emit('reservationCancelled')
      } else {
        this.$toasted.show('Er is iets mis gegaan, probeer het later opnieuw')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.actions {
  display: flex;

  .link {
    cursor: pointer;

    padding-right: 10px;
  }
}
</style>
