import { render, staticRenderFns } from "./ReservationActions.vue?vue&type=template&id=de1ac522&scoped=true&"
import script from "./ReservationActions.vue?vue&type=script&lang=js&"
export * from "./ReservationActions.vue?vue&type=script&lang=js&"
import style0 from "./ReservationActions.vue?vue&type=style&index=0&id=de1ac522&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de1ac522",
  null
  
)


    import installDirectives from "!/builds/rentmagic/rentmagic.website.vue.west-vlaanderen/node_modules/bootstrap-vue-loader/lib/runtime/installDirectives.js"
    import {VBTooltip} from 'bootstrap-vue'
    installDirectives(component, {'b-tooltip': VBTooltip})
    

export default component.exports